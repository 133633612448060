
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'
import { parseSearch } from '@/utils/env'

@Component({
  name: 'Sobeirui',
  mixins: [GlobalMixin]
})
export default class SobeiruiPage extends Vue {
  private returnUrl: string = ''
  private errorMsg: string = ''
  async created () {
    const queryStrings = parseSearch(decodeURIComponent(location.search))
    this.returnUrl = queryStrings.returnUrl
    this.errorMsg = queryStrings.msg
  }

  @Emit()
  public quit () {
    location.replace(this.returnUrl)
  }
}
